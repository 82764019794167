<template>
    <div>
        <p v-if="signUpTitle" class="d-flex text-muted text-center justify-center mb-0 mt-3">
            {{ signUpTitle }}
        </p>
        <div class="d-flex" :class="{ print_panel_login: print_panel }">
            <div class="flex-grow-1 p-3">
                <b-card no-body class="m-6 d-flex justify-center align-items-center flex-grow-1">
                    <b-row v-if="token && authUrl" align-h="center">
                        <iframe
                            :id="print_panel ? 'printable' : ''"
                            class="iframe-reset"
                            name="worksheets"
                            title="Reset Password"
                            :src="`${authUrl}/sso/reset-password/${token}/?email=${email}`"
                        ></iframe>
                    </b-row>
                    <b-row
                        v-else-if="$modals.logIn.active_tab === 'fgt_pwd'"
                        class="d-flex justify-center align-items-center"
                        style="height: 275px"
                    >
                        <b-spinner v-if="loading" variant="primary" style="width: 6rem; height: 6rem" />
                        <iframe
                            class="iframe-fgt"
                            name="worksheets"
                            title="Forgot Password"
                            :src="fgtPwdUrl"
                            :style="loading ? 'display:none' : null"
                            @load="loading = false"
                        ></iframe>
                    </b-row>
                    <b-tabs v-else small card style="width: 100%">
                        <b-tab
                            :class="print_panel ? 'p-6' : ''"
                            :active="$modals.logIn.active_tab === 'sign_up'"
                            @click="processSignUpClick"
                        >
                            <template #title>
                                <span class="tab-title domain-text-color">Sign Up</span>
                            </template>
                            <div
                                class="d-flex justify-center align-items-center"
                                :class="isBrowse && loading ? 'browse' : ''"
                                style="height: 420px"
                            >
                                <b-spinner
                                    v-if="loading"
                                    variant="primary"
                                    label="Saving"
                                    style="width: 6rem; height: 6rem"
                                />
                                <iframe
                                    :id="print_panel ? 'printable' : ''"
                                    ref="signUpWindow"
                                    class="iframe"
                                    :name="print_panel ? 'printable-worksheets' : 'worksheets'"
                                    title="Sign Up"
                                    :src="signUpUrl"
                                    :style="loading ? 'display:none' : null"
                                    @load="loading = false"
                                ></iframe>
                            </div>
                        </b-tab>

                        <b-tab
                            :class="print_panel ? 'p-6' : ''"
                            :active="$modals.logIn.active_tab === 'log_in'"
                            @click="
                                $modals.logIn.active_tab = 'log_in'
                                $eventApi.create_event('login-worksheet', 'overlay_login_tab')
                            "
                        >
                            <template #title>
                                <span class="tab-title domain-text-color">Login</span>
                            </template>
                            <div
                                class="d-flex justify-center align-items-center"
                                :class="isBrowse && loading ? 'browse' : ''"
                                style="height: 420px"
                            >
                                <b-spinner
                                    v-if="loading"
                                    variant="primary"
                                    label="Saving"
                                    style="width: 6rem; height: 6rem"
                                />
                                <iframe
                                    :id="print_panel ? 'printable' : ''"
                                    ref="loginWindow"
                                    class="iframe"
                                    :name="print_panel ? 'printable-worksheets' : 'worksheets'"
                                    title="Login"
                                    :src="loginUrlSSO"
                                    :style="loading ? 'display:none' : null"
                                    @load="loading = false"
                                ></iframe>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-card>

                <div class="flex w-full justify-center mt-3">
                    <img src="img/worksheets/brightsprout.svg" alt="brightsprout" style="height: 30px; width: 100%" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Errors from '../libraries/errors'
import { mapGetters, mapState } from 'vuex'
import DocumentHelper from '../mixins/DocumentHelper'
import StatsigHelper from '../mixins/StatsigHelper'

export default defineComponent({
    name: 'Login',
    mixins: [DocumentHelper, StatsigHelper],
    props: {
        authUrl: {
            type: String,
            default: '',
        },
        email: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            clientId: '935081350111-esramhikem0avp5evp0e29h3hcrn3sh5.apps.googleusercontent.com',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            logInForm: {
                password: '',
                remember: false,
                errors: new Errors(),
            },
            signUpForm: {
                password: '',
                remember: false,
                errors: new Errors(),
            },
            forgotPasswordForm: {
                message: new Errors(),
                errors: new Errors(),
            },
            token: '',
            isDirty: false,
            signUpFlag: false,
            signUpUrl: '',
            fgtPwdUrl: '',
            loading: false,
            loginUrlSSO: '',
            isBrowse: false,
            signUpTitle: null,
            print_panel: false,
        }
    },
    computed: {
        ...mapGetters({
            persistAction: 'document/immediatePersistAction',
            documentType: 'document/documentType',
            onboarding: 'document/onboarding',
            documentKey: 'document/documentKey',
            isAdmin: 'user/isAdmin',
            entityType: 'document/entityType',
            isLoggedIn: 'user/isLoggedIn',
            hasBeenCalled: 'user/hasBeenCalled',
            goPremium: 'document/goPremium',
            messageListener: 'user/messageListener',
            onboardingComplete: 'user/onboardingComplete',
        }),

        ...mapState(['user', 'document']),
        storedDocument() {
            return this.document.hasModification
        },
    },
    mounted() {
        this.signUpFlag = false
        this.signUpTitle = this.$modals.logIn.signUpTitle
        this.print_panel = this.$modals.logIn.print_panel

        if (!this.print_panel) {
            this.$store.dispatch('document/setOnPublishing', false)
        }

        this.$modals.logIn.signUpTitle = null
        this.$modals.logIn.print_panel = null
        this.loginButton()

        this.signUpUrl = this.getAuthUrl()
        this.fgtPwdUrl = this.getAuthUrl('forgot-password')

        this.isDirty = this.checkDirtyDocument()
        this.isBrowse = window.location.pathname.includes('browse')
        if (!this.messageListener) {
            this.$store.dispatch('user/setMessageListener', true)
            window.addEventListener('message', async (event) => {
                if (event.data.message === 'registerSuccess' && !this.hasBeenCalled) {
                    await this.$store.dispatch('user/setHasBeenCalled', true)
                    this.signUpFlag = true
                    await this.signUp(event.data)
                }

                switch (event.data) {
                    case 'loginSocialSuccess':
                    case 'loginSuccess': {
                        await this.$store.dispatch('user/setHasBeenCalled', true)
                        await this.logIn()
                        break
                    }

                    case 'resetPasswordSuccess': {
                        this.token = null
                        break
                    }

                    case 'forgotPasswordTab': {
                        this.loading = true
                        this.$modals.logIn.active_tab = 'fgt_pwd'
                        break
                    }

                    case 'loginTab': {
                        this.$modals.logIn.active_tab = 'log_in'
                        break
                    }
                }
            })
        }
        this.checkResetToken()
    },
    beforeDestroy() {
        sessionStorage.removeItem('token')
    },
    methods: {
        async loginButton() {
            this.loading = true
            this.loginUrlSSO = this.getAuthUrl('login')
            this.loading = false
        },
        async saveDocument() {
            if (!this.isLoggedIn) return

            if (this.documentType && this.storedDocument) {
                await this.$store.dispatch(this.persistAction)
                await this.$store.dispatch('document/loadLatestPublishSettings')
                return
            }

            if (this.documentType) {
                await this.$store.dispatch('document/loadLatestPublishSettings')
            }
        },
        async logIn() {
            try {
                const path = this.loginAuthFlow()

                await this.$store.dispatch('user/checkUser')

                this.$eventApi.create_event('login-succeeded')

                if (path) {
                    location.href = path
                    return
                }

                this.$modals.close('logIn')
                this.showOnboarding()

                if (this.storedDocument) {
                    this.saveDocument()
                } else if (window.doc?.copy_of_document_id) {
                    this.setInitialDocumentValues()
                    this.$store.dispatch(this.persistAction)
                }
            } catch {
                this.$modals.close('logIn')
            }
        },
        async signUp(form) {
            try {
                await this.$store.dispatch('user/checkUser')

                this.$store.dispatch('document/setOnboarding', true)
                this.$modals.close('logIn')
                this.showOnboarding()

                this.$store.dispatch('user/signUp', {
                    email: form.email,
                    password: '',
                    remember: form.remember,
                    timezone: this.timezone,
                })

                this.$eventApi.create_event('signup_succeeded')

                //log to Statsig a new-user-signup event
                this.logNewSignUp(this.documentType)

                if (this.storedDocument) {
                    this.saveDocument()
                } else if (window.doc?.copy_of_document_id) {
                    this.setInitialDocumentValues()
                    this.$store.dispatch(this.persistAction)
                }
            } catch {
                this.$modals.close('logIn')
            }
        },
        showOnboarding() {
            if ((!this.isAdmin && this.signUpFlag) || (this.print_panel && !this.onboardingComplete)) {
                return this.$bvModal.show('onboarding-modal')
            }

            if (this.user.user.trialed && this.print_panel) {
                return this.$bvModal.show('printable-modal')
            }

            if ((this.goPremium || this.print_panel) && !this.user.user.has_plan) {
                return this.$bvModal.show('checkout-modal')
            }
        },
        initSocialLogin(authType = 'login') {
            this.logSocialAuthAttempt(authType)
        },
        checkResetToken() {
            if (sessionStorage.getItem('token')) {
                this.token = sessionStorage.getItem('token')
            }
        },
        checkDirtyDocument() {
            try {
                const docType = this.documentType
                const store_key = document.id ? `state_${document.id}` : docType && `state_${docType}`

                const stored_values = localStorage.getItem(store_key)
                const lastState = stored_values && JSON.parse(stored_values)
                const statedTime = lastState?.updated_at || 0
                const updatedTime = window.doc.updated_timestamp || 0

                return statedTime > updatedTime
            } catch {
                return false
            }
        },
        loginAuthFlow() {
            let response
            if (
                this.print_panel ||
                !['/worksheet-maker', '/flashcard-maker', '/bingo-maker'].includes(window.location.pathname)
            )
                return

            if (sessionStorage.getItem('url.intended')) {
                const url = sessionStorage.getItem('url.intended')
                sessionStorage.removeItem('url.intended')

                response = url
            }

            if (!response && !this.isDirty) {
                response = '/user/my-documents'
            }

            return response
        },
        processSignUpClick() {
            if (!this.isLoggedIn) this.logSeenNewSignUpModal(this.entityType)
            this.$modals.logIn.active_tab = 'sign_up'
            this.$eventApi.create_event('sign-up-worksheet', 'overlay_signup_tab')
        },
        getAuthUrl(suffix = 'register') {
            if (this.authUrl) return `${this.authUrl}/${suffix}`

            const url = window.location.hostname

            // check for staging or dev env
            if (url.split('-').length > 1) {
                return `https://${url.split('-')[0]}-auth.brightsprout.com/${suffix}`
            }

            // check for production
            if (url.split('.').includes('com')) {
                return `https://auth.brightsprout.com/${suffix}`
            }

            // if none above then is local env
            const urlSplit = url.split('.')
            return `http://auth.brightsprout.${urlSplit[urlSplit.length - 1]}/${suffix}`
        },
    },
})
</script>

<style lang="scss">
.iframe {
    border: none;
    min-height: 425px;
    width: 400px;
    overflow: hidden;
}

.print_panel_login {
    min-height: auto;

    .tab-content {
        min-height: 450px;
    }
}

.iframe-reset {
    border: none;
    min-height: 490px;
    width: 400px;
}

.iframe-fgt {
    border: none;
    min-height: 300px;
    width: 400px;
}

#log_in_box {
    margin: 0 auto 0;
    border: 2px solid black;
    opacity: 1;
    filter: Alpha(opacity=100);
    padding: 10px 0;
}

.remove-background {
    height: 0;
    padding: 0;
}

.tab-title {
    font-size: 16px;
}

.browse {
    padding-left: 140px;
}
</style>
